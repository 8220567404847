@import '../../styles/sso-sign-in-button.scss';

.form-container {
    padding: 0 25% 0 5%;
    display: grid;
    grid-template-columns: 10% 1fr;
    grid-template-rows: auto 1fr;
}

.form-container > form {
    grid-column-start: 2;
    grid-row-start: 2;
}

.form-container > .validation-summary {
    grid-column-start: 2;
    grid-row-start: 1;
}

.form-container > .back-section {
    grid-column-start: 1;
    grid-row-start: 2;
    display: flex;
    justify-content: right;
    padding-right: 24px;
    align-items: center;
}

.button-round {
    padding: 0px;
    min-width: 36px;
}

